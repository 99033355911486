import './App.css';

import React from 'react';

import wojak from './wojak.jpg'; //
import wojakx from './wojak@2x.jpg'; //

function App() {
  
  return (
    <main>
      <h1>Use Wojak in slAck</h1>
      <p>U want to troll guud?  U need wojak brainlet to make hard trolling better guud.</p>
      <div className='slackButton'>
        <a href="https://slack.com/oauth/v2/authorize?client_id=2769840454.2006121795008&scope=commands&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
      </div>
      <img className={'wojakImage'} src={wojak} srcset={wojak + ' 1x,' + wojakx + ' 2x'} alt='typing /wojak message will expose wojak in your slack space'/>
      <p style={{marginBottom: 15}}>mAke woJaK is hRRd.  doNaTe mOniEZ an wiLL makE beTTer guD.</p>
      <div className='donateButton' data-giving-button-id='8byo9t'>
        Donate
      </div>
    </main>
  );
}

export default App;
